<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {}
  }
}
</script>

<style scoped>
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
}
</style>

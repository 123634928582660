<template>
    <div class="box">
        <!-- 悬浮栏 -->
        <div class="suspension">
            <img src="../assets/images/rightImg.png" alt />
            <div @click="jumpPage(1)">获奖模拟提案</div>
            <div @click="jumpPage(2)">入围模拟提案</div>
            <div @click="jumpPage(3)">优秀指导老师</div>
            <div @click="jumpPage(4)">优秀组织单位</div>
        </div>
        <!-- 导航条 -->
        <transition name="fade" enter-class="fade-in-enter" enter-active-class="fade-in-active" leave-class="fade-out-enter" leave-active-class="fade-out-active">
            <div :class="scrollToContent?'nav_bar_bgcolor' :'nav_bar_absoult'" class="nav_bar">

                <div :class=" scrollToContent? 'nav_bar':'nav_bar_inhead'">
                    <div class="head-wrap">
                        <div class="head_icon">
                            <img src="../assets/images/gong.png" alt />
                            <!-- <div class="icon_title">中国共青团</div> -->
                        </div>
                        <div class="head_nav">
                            <div @click="scrollToEl('head')">首页</div>
                            <div @click="scrollToEl('module_1')">活动背景</div>
                            <div @click="scrollToEl('module_2')">征集范围</div>
                            <div @click="scrollToEl('module_3')">时间安排</div>
                            <div @click="scrollToEl('module_5')">奖项设置</div>
                            <div @click="scrollToEl('module_6')">活动纪实</div>
                            <div @click="scrollToEl('module_13')">评选标准</div>
                            <div @click="scrollToEl('module_8')">参与院校</div>

                            <!-- <div @click="scrollToEl('module_10')">技术支持</div> -->
                            <div @click="scrollToEl('module_11')">关于我们</div>
                        </div>
                    </div>
                </div>

            </div>
        </transition>

        <!-- 联动内容 -->
        <div class="wrapper" ref="wrapScroll">
            <!-- 头部 -->
            <div class="head" ref="scrollOne">

            </div>
            <!-- 内容 -->
            <div class="content" ref="scrollTwo">
                <div class="module_1">
                    <div class="module_title">
                        <div>活动背景</div>
                        <div>————</div>
                        <div>CONTEST BACKGROUND</div>
                    </div>
                    <div class="m_1_content">
                        <div class="m_1_cbox">
                            <div class="text">
                                <div>“青年一代有理想、有本领、有担当，国家就有前途，民族就有希望。”当代青年是新中国70年发展的受益者，是改革开放成果的共享者，更是接力奔跑、实现中华民族伟大复兴中国梦的圆梦人。了解中国特色社会主义制度的显著优势，领悟中国奇迹的制度密码，坚定信念跟党走，是时代新人的“必修课”。</div>
                                <div>2019年9月以来，团中央维护青少年权益部、人民政协报社、中国青年报社共同举办全国青少年模拟政协提案征集活动，以模拟政协提案为载体，引导青少年亲身参与中国特色社会主义民主政治实践，领略中国制度的魅力所在。活动启动以来，受到社会各界的广泛关注。全国共有1.1万名青少年参与，经省级团委审核，推荐1884件模拟提案作品参与全国评审。活动依托“共青团中央”、“学校共青团”、“团中央权益部”等微信公众号进行宣传，开设专栏进行网络投票和政协知识线上答题，总计参与人数61.6万人，页面点击量192.5万人次。</div>
                                <div>按地方推荐、团中央初审、专家评审、网络投票、评审会终审、网络公示等6个环节，最终确定“优秀模拟政协提案”100件，其中“最佳模拟政协提案”10件。同时，确定20个“优秀组织单位”、20位“优秀指导老师”。</div>
                                <div>活动得到各级政协机关大力支持，各地团组织积极组织发动，引导广大青年奔向广阔天地、深入基层一线，聚焦经济发展、民生保障、文化事业、生态环保等领域，深入基层开展调研，提出政策建议。从获奖作品看，有的聚焦乡村振兴中的青年参与和发展，有的围绕垃圾分类、共享单车、快递包装回收等城市管理难题，有的关注未成年人权益侵害热点问题，充分展现了以青年视角、理性思维关注社会发展的青春情怀。不少学生反映，活动将思政课上抽象的政治概念变成了可参与、可实践的生动形式。一些政协委员认为，通过这种方式进行中国特色社会主义制度宣传教育，可以让青少年进一步知晓制度优势、增强制度自信，提高青少年的社会实践能力、政策分析能力、政治参与能力，很有意义。</div>
                                <div>未来，主办方将进一步把活动组织好、取得实效，引导广大青少年自觉做中国特色社会主义制度的坚定捍卫者、推进国家治理体系和治理能力现代化的积极参与者。</div>
                            </div>
                            <div class="video" style="background:url(https://cppcc-public.oss-cn-beijing.aliyuncs.com/videos/6e68ce60-6b66-11ea-b00e-63173bab798f.mp4?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto) no-repeat 0 0 ;background-size:100%;">
                                <video poster="https://cppcc-public.oss-cn-beijing.aliyuncs.com/videos/6e68ce60-6b66-11ea-b00e-63173bab798f.mp4?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto" src="https://cppcc-public.oss-cn-beijing.aliyuncs.com/videos/6e68ce60-6b66-11ea-b00e-63173bab798f.mp4" controls="controls"></video>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_2">
                    <div class="module_title">
                        <div>征集范围</div>
                        <div>————</div>
                        <div>CONTEST SCOPE</div>
                    </div>
                    <div class="m_2_content">
                        <div class="m2_block" v-for="(item,index) in m2Data" :key="index">
                            <div class="m2_icon">
                                <img :src="item.img" alt />
                            </div>
                            <div class="m2_text">{{item.text}}</div>
                        </div>
                    </div>
                </div>
                <div class="module_3">
                    <div class="module_title">
                        <div>时间安排</div>
                        <div>————</div>
                        <div>SCHEDULE</div>
                    </div>
                    <div class="time_shaft">
                        <div class="time_shaft_slide">
                            <div class="left_slide" style="margin-top:65%">
                                <div>2019年10月31日前</div>
                                <div>调研和撰写提案</div>
                            </div>
                            <div class="left_slide" style="margin-top:80%">
                                <div>2019年12月31日前</div>
                                <div>网络投票及专家评审</div>
                            </div>
                        </div>
                        <div class="time_shaft_center">
                            <div class="point">
                                <div class="s_point"></div>
                            </div>
                            <div class="i" style="height:25%;"></div>
                            <div class="point">
                                <div class="s_point"></div>
                            </div>
                            <div class="i" style="height:13%;"></div>
                            <div class="point">
                                <div class="s_point"></div>
                            </div>
                            <div class="i" style="height:22%;"></div>
                            <div class="point">
                                <div class="s_point"></div>
                            </div>
                            <div class="i" style="height:15%;"></div>
                            <div class="point">
                                <div class="s_point"></div>
                            </div>
                        </div>
                        <div class="time_shaft_slide">
                            <div class="right_silde">
                                <div>2019年09月15日前</div>
                                <div>组织动员</div>
                            </div>
                            <div class="right_silde" style="margin-top:80%">
                                <div>2019年11月15日前</div>
                                <div>省级团委择优推荐</div>
                            </div>
                            <div class="right_silde" style="margin-top:83%">
                                <div>2020年3月</div>
                                <div>公布获奖名单</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_5">
                    <div class="module_title">
                        <div>奖项设置</div>
                        <div>————</div>
                        <div>AWARDS</div>
                    </div>
                    <div class="m5_box">
                        <div class="m5_cbox">
                            <div class="m5_item" v-for="(item,index) in m5Data" @click="jumpPage(item.herf)" :key="index">
                                <div class="m5_avatar">
                                    <img :src="item.img" alt />
                                </div>
                                <div class="m5_title">
                                    <a>{{item.title}}</a>
                                </div>

                                <div class="m5_num">
                                    <span>{{item.num}}</span>
                                    <span>{{item.text}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_6">
                    <div class="m6_black">
                        <div class="module_title">
                            <div>活动纪实</div>
                            <div>————</div>
                            <div>CONTEST RECORDS</div>
                        </div>
                        <div class="m6_box">
                            <div class="video">
                                <video src="https://ayx-cppcc.oss-cn-beijing.aliyuncs.com/videos/70c74bb0-6d72-11ea-9756-c73fb3ba900f.mp4" controls="controls" poster="https://ayx-cppcc.oss-cn-beijing.aliyuncs.com/videos/70c74bb0-6d72-11ea-9756-c73fb3ba900f.mp4?x-oss-process=video/snapshot,t_8000,f_jpg,w_0,h_0,m_fast,ar_auto"></video>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_13">
                    <div class="module_title">
                        <div>评选标准</div>
                        <div>————</div>
                        <div>SELECTION CRITERIA</div>
                    </div>
                    <div class="m13_box">
                        <div class="m13_cbox">
                            <div class="m13_item" v-for="(item,index) in m13Data" :key="index">
                                <div class="m13_item_top">{{item.title}}</div>
                                <div class="m13_item_bottom">{{item.context}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_14">
                    <div class="module_title">
                        <div>媒体报道</div>
                        <div>————</div>
                        <div>NEWS</div>
                    </div>
                    <div class="m14_box">
                        <div class="m14_item" v-for="(item,index) in m14Data" :key="index">
                            <a :href="item.link" target="_blank" rel="noopener noreferrer">{{item.title}}</a>
                        </div>
                    </div>
                </div>
                <div class="module_7">
                    <div class="module_title">
                        <div>主办单位</div>
                        <div>————</div>
                        <div>SPONSOR</div>
                    </div>
                    <div class="m7_item">共青团中央维护青少年权益部</div>
                    <div class="m7_item">人民政协报社</div>
                    <div class="m7_item">中国青年报社</div>
                    <div class="module_title" style="margin-top:70px;">
                        <div>友情合作</div>
                        <div>————</div>
                        <div>COOPERATIVE PARTNER</div>
                    </div>
                    <div class="m7_item">假如我是委员</div>
                </div>
                <div class="module_8">
                    <div class="module_title">
                        <div>参与院校</div>
                        <div>————</div>
                        <div>PARTICIPATING SCHOOLS</div>
                    </div>
                    <div class="m8_box">
                        <div class="m8_title">
                            以下是参与活动的部分学校，本次活动参与学校数达
                            <span style="font-size:33px;color:#5C93E9;">1000+</span> 所
                        </div>
                        <img class="m8_image" src="../assets/images/swiper1.png" alt />
                    </div>
                </div>
                <div class="module_11">
                    <div class="module_title">
                        <div>关于我们</div>
                        <div>————</div>
                        <div>ABOUT US</div>
                    </div>
                    <div class="m11_box">
                        <div class="m11_left">
                            <div class="m11_text1">地址：北京市东城区前门东大街10号</div>
                            <div class="m11_text1">电话：(010)85212148</div>
                            <div class="m11_text1">邮箱：tzydbxsc@sina.com</div>
                        </div>
                        <div class="m11_right">
                            <div class="m11_r_item">
                            </div>
                            <div class="m11_r_item">
                                <img src="../assets/images/m12_1.png" alt />
                                <div class="m11_text">官方公众号</div>
                            </div>
                            <div class="m11_r_item">
                                <img src="../assets/images/m12_3.jpg" alt />
                                <div class="m11_text">官方抖音</div>
                            </div>
                            <div class="m11_r_item">
                                <img src="../assets/images/m12_4.jpg" alt />
                                <div class="m11_text">假如我是委员</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module_12">
                    <div>Copyright © 2019共青团中央维护青少年权益部所有，未经许可不得转载</div>
                    <!-- <div>北京新新向荣科技有限公司提供技术支持</div> -->
                </div>
            </div>
        </div>
        <!-- 置顶按钮 -->
        <div class="scroll_top" v-show="scrollToContent" @click="scrollToEl('head')">
            <img src="../assets/images/home_scroll_top.png" alt="">
        </div>
    </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
    data() {
        return {
            scrollToContent: false,
            screenHeight: document.body.clientHeight,
            slide: [
                require("../assets/images/swiper1.png"),
                require("../assets/images/swiper1.png"),
                require("../assets/images/swiper1.png")
            ],
            swiperOption: {
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                loop: true,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false
                },
                pagination: {
                    el: ".swiper-pagination"
                }
            },
            wrapScroll: {}, //滚动容器对象
            m2Data: [
                { img: require("../assets/images/m2_1.png"), text: "经济发展" },
                { img: require("../assets/images/m2_2.png"), text: "民生保障" },
                { img: require("../assets/images/m2_3.png"), text: "文化事业" },
                { img: require("../assets/images/m2_4.png"), text: "生态环保" },
                {
                    img: require("../assets/images/m2_6.png"),
                    text: "青少年成长"
                }
            ],
            m4Data: [
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                },
                {
                    img: require("../assets/images/gong.png"),
                    name: "张晓明",
                    info: "张亮亮团中央委员中央 书记国家干部，优秀干部"
                }
            ],
            m5Data: [
                {
                    img: require("../assets/images/m4_1.png"),
                    title: "最佳模拟政协提案",
                    herf: 1,
                    num: 10,
                    text: "件"
                },
                {
                    img: require("../assets/images/m4_2.png"),
                    title: "优秀模拟政协提案",
                    herf: 1,
                    num: 100,
                    text: "件"
                },
                {
                    img: require("../assets/images/m4_3.png"),
                    title: "优秀指导老师",
                    herf: 3,
                    num: 20,
                    text: "位"
                },
                {
                    img: require("../assets/images/m4_4.png"),
                    title: "优秀组织单位",
                    herf: 4,
                    num: 20,
                    text: "个"
                }
            ],
            m9Data: [
                { img: require("../assets/images/m9_7.png") },
                { img: require("../assets/images/m9_8.png") },
                { img: require("../assets/images/m9_9.png") },
                { img: require("../assets/images/m9_4.png") },
                { img: require("../assets/images/m9_1.png") },
                { img: require("../assets/images/m9_2.png") },
                { img: require("../assets/images/m9_3.png") },
                { img: require("../assets/images/m9_5.png") },
                { img: require("../assets/images/m9_6.png") },
                { img: require("../assets/images/m9_10.png") },
                { img: require("../assets/images/m9_11.png") },
                { img: require("../assets/images/m9_12.png") },
                { img: require("../assets/images/m9_13.png") },
                { img: require("../assets/images/m9_14.png") },
                { img: require("../assets/images/m9_15.png") },
                { img: require("../assets/images/m9_16.png") },
                { img: require("../assets/images/m9_17.png") },
                { img: require("../assets/images/m9_18.png") },
                { img: require("../assets/images/m9_19.png") },
                { img: require("../assets/images/m9_20.png") },
                { img: require("../assets/images/m9_21.png") },
                { img: require("../assets/images/m9_22.png") },
                { img: require("../assets/images/m9_23.png") },
                { img: require("../assets/images/m9_24.png") },
                { img: require("../assets/images/m9_25.png") },
                { img: require("../assets/images/m9_26.png") },
                { img: require("../assets/images/m9_27.png") },
                { img: require("../assets/images/m9_28.png") }
            ],
            m13Data: [
                {
                    title: "提案选题",
                    context:
                        "选题准确，反映情况真实，围绕国家经济社会发展中的重要问题、青少年成长发展中的普遍问题、人民群众关心的问题建言献策。"
                },
                {
                    title: "调研过程",
                    context:
                        "深入社会实际、走进基层群众开展调查研究；突出问题导向，根据需要综合选用多种研究方法，调研思路清晰，方法科学，真实有效；支撑材料充分、详实。"
                },
                {
                    title: "分析论证",
                    context:
                        "能够透过现象挖掘问题本质，论证过程逻辑严密，论据充分，论述有力，说服力强，观点和结论明确。"
                },
                {
                    title: "对策建议",
                    context:
                        "建议明确具体，操作性和针对性强，对宏观决策、长远规划、解决实际问题有重要参考价值。"
                },
                {
                    title: "结构文字",
                    context:
                        "按照“一事一案”原则撰写提案，符合提案格式，结构合理、条理清晰、重点突出，语句通顺、文字简洁、表意准确。"
                }
            ],
            m14Data: [
                {
                    title: "优秀！这所高中的学生提案，连续四年被带上全国两会！",
                    link: "https://mp.weixin.qq.com/s/09tqGEOZlMXXPEaBTPxcsA"
                },
                {
                    title:
                        "共青团上海市委参与2019年全国青少年模拟政协提案活动侧记",
                    link: "http://rmzxb.bzzb.tv/shareart_2871955_5.html"
                },
                {
                    title:
                        "一堂走心的政治课—长春市全国青少年模拟政协提案征集活动小记",
                    link: "http://rmzxb.bzzb.tv/shareart_2906858_5.html"
                },
                {
                    title:
                        "外卖小哥加塞、逆行、闯红灯，在校大学生干了这件事儿！",
                    link: "https://mp.weixin.qq.com/s/KvmoCMX_RyhiBNRvGVwNXA"
                },
                {
                    title:
                        "小社团 大社会———陕西中学生“模拟政协”社团的探索与实践",
                    link: "http://rmzxb.bzzb.tv/shareart_2857784_5.html"
                },
                {
                    title: "当“团团”遇到“商商” ，会碰撞出怎样的青春火花？",
                    link: "https://mp.weixin.qq.com/s/mOXtXFSyva4ucwC35OJUyQ"
                }
                // {
                //   title:"",
                //   link:""
                // },
            ]
        };
    },
    methods: {
        jumpPage(value) {
            this.$router.push({ path: "/proposal", query: { key: value } });
        },
        //监听滚动
        // handleScroll() {
        //     //获取滚动时的高度
        //     let scrollTop =
        //         window.pageYOffset ||
        //         document.documentElement.scrollTop ||
        //         document.body.scrollTop;
        //     let oneHeight = this.$refs.scrollTwo.offsetHeight;

        //     if (scrollTop > oneHeight) {
        //         //这是滑动到scrollTwo的距离要做的操作
        //         this.scrollToContent = true;
        //         console.log("这是滑动到scrollTwo的距离要做的操作");
        //     } else {
        //         this.scrollToContent = false;
        //     }
        // },
        //初始化滚动
        // initScroll() {
        //     this.wrapScroll = new BScroll(this.$refs.wrapScroll);
        // },
        //滚动到指定距离
        // scrollTo(x, y) {
        //     this.wrapScroll.scrollTo(x, y, 300);
        // },

        //滚动到指定元素
        scrollToEl(elm) {
            console.log(elm, "<==elm");
            //获取元素
            let el = document.getElementsByClassName(elm)[0];
            console.log(el.offsetTop, "<==el.offsetTop");
            console.log(el, "<==el");

            this.$nextTick(function() {
                window.scrollTo({ behavior: "smooth", top: el.offsetTop - 60 });
            });
        }
    },
    created() {
        //渲染dom后初始化滚动
        // this.$nextTick(() => {
        //     this.initScroll();
        // });
    },
    mounted() {
        window.addEventListener("scroll", () => {
            const top = document.scrollingElement.scrollTop;

            // const left = document.scrollingElement.scrollLeft;
            if (top >= 667) {
                this.scrollToContent = true;
                //
                // this.$refs.nav.style.left = `${-left}px`;
            } else {
                this.scrollToContent = false;
                // this.$refs.nav.style.left = 0;
            }
        });

        // const that = this;
        // window.onresize = () => {
        //     return (() => {
        //         window.screenHeight = document.body.clientHeight;
        //         that.screenHeight = window.screenHeight;
        //     })();
        // };
        // let head = document.getElementsByClassName("head")[0];
        // head.style.height = this.screenHeight + "px";
        // window.addEventListener("scroll", this.handleScroll);
    },
    watch: {
        // screenHeight(val) {
        //     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        //     if (!this.timer) {
        //         // 一旦监听到的screenHeight值改变，就将其重新赋给data里的screenHeight
        //         this.screenHeight = val;
        //         this.timer = true;
        //         let that = this;
        //         // let head = document.getElementsByClassName("head")[0];
        //         // head.style.height = that.screenHeight + "px";
        //         that.timer = false;
        //     }
        // }
    },
    destroyed() {
        // document.removeEventListener("scroll", this.handleScroll);
    }
};
</script>

<style>
.fade-in-active,
.fade-out-active {
    transition: all 0.6s ease;
}
.fade-in-enter,
.fade-out-active {
    opacity: 0;
}
.module_1 {
    width: 100%;
    height: 1100px;
    margin-top: 60px;
    /* border: 1px solid red; */
}
.module_2 {
    width: 100%;
    margin-top: 30px;
    height: 450px;
    /* border: 1px solid red; */
}
.module_3 {
    width: 100%;
    margin-top: 30px;
    height: 720px;
    background: #ffffff;
    /* border:1px solid red; */
}
.module_4 {
    width: 100%;
    margin-top: 30px;
    height: 1080px;
    background: #f8f8f8;
    /* border: 1px solid yellowgreen; */
}
.module_5 {
    width: 100%;
    margin-top: 30px;
    height: 580px;
    /* border: 1px solid yellowgreen; */
}
.module_6 {
    width: 100%;
    height: 580px;
    /* color: #ffffff; */
}
.m6_black {
    width: 100%;
    height: 100%;
    /* background: rgba(0,0,0,0.5);r:red g:green b:blue a:alpha(半透明):0-1,1是不透明，0是全透明 */
}
.module_7 {
    width: 100%;
    margin-top: 30px;
    height: 700px;
    /* border: 1px solid yellowgreen; */
}
.module_8 {
    width: 100%;
    margin-top: 30px;
    height: 750px;
    /* border: 1px solid yellowgreen; */
}
.module_9 {
    margin-top: 30px;
    width: 100%;
    height: 750px;
    /* border: 1px solid yellowgreen; */
}
.module_10 {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    /* border: 1px solid yellowgreen; */
}
.module_11 {
    margin-top: 30px;
    width: 100%;
    height: 380px;
    /* background: #e4eeff; */
}
.module_12 {
    width: 100%;
    /* border: 1px solid yellowgreen; */
    background: #2d2d2d;
    color: #f8f8f8;
    padding: 30px 0;
    /* height: 100px; */
    /* padding-top: 20px; */
}
.module_13 {
    margin-top: 30px;
    width: 100%;
    /* border: 1px solid yellowgreen; */
    height: 450px;
}
.module_14 {
    /* margin-top: 20px; */
    width: 100%;
    /* border: 1px solid yellowgreen; */
    height: 400px;
    /* height: 600px; */
}
/* ========== m 14 =========== */
.m14_box {
    height: 300px;
    /* height: 500px; */
    /* border: 1px solid yellowgreen; */
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.m14_item {
    width: 550px;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    padding-left: 15px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 10px;
    /* border: 1px solid yellowgreen; */
    border-left: 7px solid #4d88e9;
}
.m14_item a {
    text-decoration: none;
    color: #2d2d2d;
}
.m14_item a:hover {
    text-decoration: none;
    color: #4d88e9;
}
/* ========== m 13 =========== */
.m13_box {
    height: 500px;
    /* border: 1px solid yellowgreen; */
    width: 1000px;
    margin: 0 auto;
}
.m13_title {
    width: 100%;
    height: 150px;
    margin-bottom: 30px;
    line-height: 150px;
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(126, 170, 243, 1);
}
.m13_cbox {
    width: 100%;
    height: 232px;
    display: flex;
    margin-top: 60px;
}
.m13_item {
    width: 254px;
    height: 232px;
    border-right: 2px dashed #7eaaf3;
}
.m13_item:nth-child(5) {
    width: 254px;
    height: 232px;
    /* border-right: 2px dashed #7eaaf3; */
    border: none;
}
.m13_item_top {
    width: 130px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 30px;
    margin-left: 45px;
    background: rgba(126, 170, 243, 1);
    border-radius: 21px;
}
.m13_item_bottom {
    width: 155px;
    height: 160px;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(84, 119, 176, 1);
    line-height: 24px;
}
/* ========== m 12 =========== */

.module_12 div {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
}

/* ========== m 11 =========== */
.m11_box {
    width: 1000px;
    height: 220px;
    /* border: 1px solid red; */
    margin: 0 auto;
    display: flex;
    margin-top: 50px;
}
.m11_left {
    width: 381px;
    height: 200px;
    /* border: 1px solid yellowgreen; */
    margin-top: 10px;
    margin-left: 20px;
}
.m11_right {
    width: 610px;
    height: 200px;
    /* border: 1px solid yellowgreen; */
    margin-left: 90px;
    font-size: 18px;
    display: flex;
    margin-top: 10px;
    text-align: center;
}
.m11_r_item {
    width: 130px;
    height: 200px;
    margin-left: 20px;
    /* border: 1px solid yellowgreen; */
}
.m11_r_item img {
    width: 100px;
    height: 100px;
    margin-left: 5px;
}
.m11_text1 {
    height: 40px;
    line-height: 40px;
    /* border: 1px solid yellowgreen; */
    font-size: 18px;
    font-weight: 400;
}
.m11_text {
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    /* border: 1px solid yellowgreen; */
    font-size: 16px;
}
/* ========== m 10 =========== */
.m10_box {
    width: 1000px;
    height: 500px;
    /* border: 1px solid red; */
    margin: 0 auto;
}
.m10_title {
    width: 100%;
    height: 150px;
    line-height: 150px;
    text-align: center;
    color: #5c93e9;
}
.m10_box img {
    width: 100%;
    height: 400px;
}
/* ========== m 9 =========== */
.m9_box {
    width: 1000px;
    height: 610px;
    /* border: 1px solid yellowgreen; */
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.m9_item {
    width: 140px;
    height: 140px;
    /* border: 1px solid gainsboro; */
    margin-left: 5px;
    margin-top: 7px;
    display: flex;
    margin-right: 5px;
    overflow: hidden;
}
.m9_icon {
    width: 140px;
}
.m9_icon img {
    width: 140px;
}
/* ========== m 8 =========== */
.m8_box {
    width: 1200px;
    height: 600px;
    /* border: 1px solid yellowgreen; */
    margin: 0 auto;
}
.m8_title {
    width: 100%;
    height: 100px;
    line-height: 100px;
    margin-bottom: 30px;
    text-align: center;
    color: #777777;
}

/* ========== m 7 =========== */
.m7_item {
    width: 1000px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #5c93e9;
    font-size: 32px;
    letter-spacing: 2px;
    /* font-size: 38px; */
    margin: 40px auto 0;
    /* font-weight: 400; */
    /* color: rgba(92, 147, 233, 1); */
}
/* ========== m 6 =========== */
.m6_box {
    width: 1000px;
    height: 440px;
    margin: 0 auto;
    /* border: 1px solid red; */
}
.m6_item {
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.m6_item span:nth-child(1) {
    font-size: 28px;
    margin-right: 20px;
}

/* ========== m 5 =========== */
.m5_box {
    width: 100%;
    height: 500px;
    /* border: 1px solid red; */
}
.m5_cbox {
    width: 1000px;
    height: 500px;
    margin: 0 auto;
    /* border: 1px solid red; */
    display: flex;
}
.m5_item {
    width: 260px;
    height: 340px;
    /* border: 1px solid red; */
    border: 1px solid #e3e4e6;
    box-shadow: 1px 1px 1px 1px #e3e4e6;
    margin: 30px;
    cursor: pointer;
    border-radius: 5px;
}
.m5_avatar {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
}
.m5_avatar img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.m5_title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
    color: #1a76ef;
}
.m5_num {
    width: 180px;
    height: 100px;
    /* line-height: 40px; */
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: gray;
    margin-top: 10px;
}
.m5_num span:nth-child(1) {
    width: 180px;
    height: 100px;
    /* line-height: 40px; */
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    color: gray;
    margin-right: 5px;
}
/* ========== m 4 =========== */
.m4_box {
    width: 100%;
    height: 980px;
    /* border: 2px solid red; */
}
.m4_cbox {
    width: 1000px;
    height: 980px;
    /* border: 1px solid red; */
    margin: 0 auto;
    display: flex;

    flex-wrap: wrap;
}
.m4_item {
    width: 230px;
    height: 290px;
    margin-right: 30px;
    margin-left: 30px;
    background: #ffffff;
}
.m4_avatar {
    width: 140px;
    height: 140px;
    /* border: 1px solid yellowgreen; */
    margin: 0 auto;
    margin-top: 30px;
}
.m4_name {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a76ef;
}
.m4_info {
    width: 180px;
    height: 100px;
    /* line-height: 40px; */
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: gray;
}
/* ======== m 2 ====== */
.m_2_content {
    width: 1000px;
    height: 270px;
    /* border:1px solid red; */
    margin: 0 auto;
    display: flex;
    /* flex-wrap: wrap; */
}
/* .m2_block:nth-child(1) {
  width: 190px;
  height: 220px;
  background: #ffffff;
  border-radius: 10px;
} */
.m2_block {
    width: 190px;
    height: 220px;
    /* border:1px solid red; */
    box-shadow: 1px 1px 2px 2px rgba(190, 205, 216, 0.336);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 10px;
}
.m2_icon {
    width: 100%;
    height: 55px;
    margin-top: 40px;
    /* border:1px solid yellow; */
}
.m2_icon img {
    width: 55px;
    height: 55px;
    margin-left: 50px;
    /* border:1px solid yellow; */
}
.m2_text {
    width: 100%;
    height: 80px;
    margin-top: 20px;
    line-height: 80px;
    text-align: center;
    color: #4d88e9;
    font-size: 20px;
    /* border:1px solid yellowgreen; */
}

/* ========  m_1  ======= */
.m_1_content {
    width: 100%;
    height: 950px;
    /* border: 1px solid red; */
}
.m_1_cbox {
    width: 1000px;
    height: 950px;
    margin: 0 auto;
    /* border: 1px solid yellow; */
}
.text {
    width: 1000px;
    font-size: 14px;
    font-family: Noto Sans S Chinese;
    /* font-weight: normal; */
    color: rgba(90, 89, 89, 1);
    font-weight: 400;
    line-height: 28px;
    /* border: 1px solid green; */
    margin-bottom: 60px;
}
.text div {
    /* border: 1px solid red; */
    margin-bottom: 5px;
    text-indent: 2em;
}
.video {
    margin: 0 auto;
    /* width: 420px;
  height: 240px; */
    width: 740px;
    height: 420px;
    /* border: 2px solid green; */
    /* align-items: center; */
}
.video video {
    width: 100%;
    height: 100%;
    outline: none;
}
.module_title {
    margin: 0 auto 30px;
    width: 1000px;
    height: 80px;
    text-align: center;
    padding-top: 30px;
    margin-bottom: 30px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.module_title div {
    width: 100%;
    height: 30px;
    line-height: 30px;
}
.module_title div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    /* margin-top: 20px; */
}
.module_title div:nth-child(2) {
    color: #5c93e9;
    height: 10px;
    line-height: 10px;
}
.module_title div:nth-child(3) {
    font-size: 12px;
    color: #999999;
    height: 15px;
    line-height: 15px;
}
/* * {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
} */
.box {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: absolute;
}
.head {
    /* width: 100%;
  height: 700px; */
    width: 100%;
    min-width: 1200px;
    height: 700px;
    background: 100% url("../assets/images/banner2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.nav_bar {
    width: 100%;
    height: 80px;
    min-width: 1430px;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: space-around;
    /* top: 0; */
    z-index: 35;
}
.nav_bar_absoult {
    position: absolute;
    top: 0;
    left: 0;
}
.nav_bar_bgcolor {
    position: fixed;
    top: 0;
    background: #215ed9;
}
.head-wrap {
    padding: 0 38px;
    width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.nav_bar_inhead {
    /* width: 1200px; */
    width: 100%;
    min-width: 1200px;
    height: 80px;
    padding: 0 38px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* top: 5px; */
    z-index: 9999;
}
.head_icon {
    /* width: 16%; */
    height: 75px;
    display: flex;
}
.head_icon img {
    height: 75px;
    width: 75px;
}
.icon_title {
    width: 150px;
    min-width: 150px;
    height: 75px;
    font-weight: 400;
    line-height: 75px;
    color: #ffffff;
    font-size: 22px;
}
.head_nav {
    height: 40px;
    margin-top: 20px;
    line-height: 40px;
    display: flex;
}
.head_nav div {
    text-align: center;
    flex: 1;
    min-width: 75px;
    color: #ffffff;
}
.head_nav div:hover {
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}
/* 时间安排 */
.time_shaft {
    width: 1000px;
    height: 570px;
    /* border: 1px solid #5C93E9; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.time_shaft_slide {
    width: 240px;
    height: 100%;
    /* border:1px solid goldenrod;  */
    /* margin: 0 auto; */
}
.left_slide,
.right_silde {
    width: 100%;
    height: 60px;
    /* border:1px solid goldenrod;  */
}
.left_slide div,
.right_silde div {
    width: 100%;
    height: 30px;
    color: rgba(38, 97, 187, 1);
    line-height: 30px;
    text-align: center;
    /* border:1px solid goldenrod;  */
}
.left_slide div:nth-child(1),
.right_silde div:nth-child(1) {
    font-size: 21px;
    font-weight: 400;
}
.left_slide div:nth-child(2),
.right_silde div:nth-child(2) {
    font-size: 26px;
    font-weight: 400;
}
.time_shaft_center {
    width: 70px;
    height: 100%;
    /* border:1px solid #5C93E9;  */
    margin: 0 50px;
}
.point {
    /* 17px */
    width: 27px;
    height: 27px;
    border-radius: 27px;
    margin-right: 1px;
    /* border: 1px solid red; */
    background: rgb(184, 202, 230);
    padding: 6px;
    margin-left: 30%;
}
.s_point {
    width: 14px;
    height: 14px;
    padding: 2px;

    border-radius: 14px;
    background: #5c93e9;
}
.i {
    width: 50%;
    border-right: 3px solid rgb(188, 205, 233);
}
.swiper-slide {
    width: 200px;
    height: 420px;
    /* line-height: 500px; */
    font-size: 50px;
    /* border: 1px solid red; */
    text-align: center;
}
.m8_image {
    padding: 0 58px;
    width: 1200px;
    height: 400px;
    margin: 0 auto;
}
.swiper_img {
    width: 100%;
    height: 100%;
}
.swiper-pagination {
    width: 300px;
    height: 20px;
    margin-top: 100px;
    /* border: 1px solid red; */
}
.wrapper {
    min-width: 1430px;
    position: absolute;
    height: 500px;
    width: 100%;
    /* border: 2px solid palevioletred; */
    z-index: 34;
    font-weight: 500;
}
.suspension {
    width: 154px;
    height: 200px;
    /* border: 1px solid red; */
    z-index: 43;
    position: fixed;
    right: 0;
    top: 250px;
}
.suspension img {
    width: 154px;
    height: 188px;
}
.suspension div {
    width: 133px;
    height: 40px;
    line-height: 40px;
    /* text-align: center; */
    padding-left: 20px;
    font-size: 16px;
    margin: 2px;
    color: #ffffff;
    /* border: 1px solid red; */
    background: #7eaaf3;
    cursor: pointer;
}
.suspension div:hover {
    background: #e4edfd;
    color: #6b9ef3;
}
.content {
    width: 100%;
    min-width: 1200px;
    height: 1200px;
    background: #ffffff;
    border-top: 1px solid #ffffff;
}

.scroll_top {
    width: 48px;
    height: 48px;
    position: fixed;
    bottom: 100px;
    right: 60px;
    border-radius: 50%;
    box-shadow: 0 6px 24px 0 rgba(204, 204, 204, 0.3);
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: #fff;
    z-index: 99999999;
}
.scroll_top img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import VueRouter from 'vue-router';
import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
import 'swiper/dist/css/swiper.css'  //引入样式
import { } from 'ant-design-vue/dist/antd.css';
Vue.use(vueSwiper)  //使用插件

Vue.use(VueRouter);
Vue.use(Antd);

Vue.config.productionTip = false
Vue.prototype.$events = new Vue();
var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?16ec9b56c5d2be2a2b01180970cedd72";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', redirect: '/home', meta: { auth: false } },
    { path: '/home', component: () => import('./components/HomePage') },
    { path: '/proposal', component: () => import('./components/proposal') },
  ]
});
router.beforeEach((to, from, next) => {
  if (_hmt) {
      if (to.path) {
          _hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next();
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

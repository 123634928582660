<template>
  <div class="boxp">
    <!-- 悬浮栏 -->
    <div class="suspensionP">
      <!-- <img src="http://ayx-cppcc.oss-cn-beijing.aliyuncs.com/images/c7dfb5f0-6d80-11ea-9756-c73fb3ba900f.png" alt /> -->
      <img src="../assets/images/rightImg.png" alt />
    </div>
    <!-- 导航条 -->
    <!-- 联动内容 -->
    <!-- 头部 -->
    
    <div class="headp" ref="scrollOne">
      <div class="nav_bar_inheadp">
        <div class="headp_icon" @click="goHome">
          <img src="../assets/images/gong.png" alt />
          <div class="icon_title">中国共青团</div>
        </div>
        <div class="headp_nav">
          <div @click="goHome">返回首页</div>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="contentp">
      <!-- 切换按钮 -->
      <div class="contentp_nav">
        <div class="nav_button">
          <a-button class="a_button" :type="buttonType1" @click="changeClass(1)">获奖提案</a-button>
          <a-button class="a_button" :type="buttonType2" @click="changeClass(2)">全部提案</a-button>
          <a-button class="a_button" :type="buttonType3" @click="changeClass(3)">优秀指导老师</a-button>
          <a-button class="a_button" :type="buttonType4" @click="changeClass(4)">优秀组织单位</a-button>
        </div>
        <!-- <div class="nav_search">
          <input type="search" placeholder="搜索" v-model="searchValue" @keyup.enter="onSearch" />
          <img src="../assets/images/search.png" @click="onSearch" alt />
        </div> -->
      </div>
      <!--  优秀提案  -->
      <div class="winProposal" v-show="classShow1">
        <div class="contentp_title"></div>
        <!-- <div class="contentp_title">本次活动所有获奖提案均可以在“假如我是委员”微信小程序上进行搜索查看，并可以查看政协委员和虚拟政协委员的互动，其他提案将陆续发布至平台。</div> -->
        <a-table
          bordered
          :columns="columns1"
          :rowKey="record => record._id"
          :dataSource="goodProposal"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :rowClassName="(record, index) => {return index % 2 === 0 ? 'light' : 'blue'}"
        >
          <div
            slot="filterDropdown"
            slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="c => searchInput = c"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
              style="width: 188px; margin-bottom: 8px; display: block;"
            />
            <a-button
              type="primary"
              @click="() => handleSearch(selectedKeys, confirm)"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
            >Search</a-button>
            <a-button
              @click="() => handleReset(clearFilters)"
              size="small"
              style="width: 90px"
            >Reset</a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="customRender" slot-scope="text">
            <span v-if="searchText">
              <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
              </template>
            </span>
            <template v-else>{{text}}</template>
          </template>
        </a-table>
      </div>
      <!-- 全部提案  -->
      <div class="winProposal" v-show="classShow2">
        <div class="contentp_title"></div>
        <!-- <div class="contentp_title">本次活动所有获奖提案均可以在“假如我是委员”微信小程序上进行搜索查看，并可以查看政协委员和虚拟政协委员的互动，其他提案将陆续发布至平台。</div> -->
        <a-table
          bordered
          :columns="columns"
          :rowKey="record => record._id"
          :dataSource="centralProposal"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :rowClassName="(record, index) => {return index % 2 === 0 ? 'light' : 'blue'}"
        >
          <div
            slot="filterDropdown"
            slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="c => searchInput = c"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
              style="width: 188px; margin-bottom: 8px; display: block;"
            />
            <a-button
              type="primary"
              @click="() => handleSearch(selectedKeys, confirm)"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
            >Search</a-button>
            <a-button
              @click="() => handleReset(clearFilters)"
              size="small"
              style="width: 90px"
            >Reset</a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="customRender" slot-scope="text">
            <span v-if="searchText">
              <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
              </template>
            </span>
            <template v-else>{{text}}</template>
          </template>
        </a-table>
      </div>
      <!-- 优秀指导老师 -->
      <div class="greatTeacher" v-show="classShow3">
        <div class="teacherp_box">
          <div class="teacherp_cbox">
            <div class="teacherp_item" v-for="(item,index) in teacherpData" :key="index">
              <div class="teacherp_avatar">
                <img :src="item.img" alt />
              </div>
              <div class="teacherp_name">{{item.name}}</div>
              <div class="teacherp_info">{{item.info}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优秀组织单位 -->
      <div class="greatUnit" v-show="classShow4">
        <!-- <img src="../assets/images/swiper1.png" alt /> -->
        <div class="unit_box">
                <img v-for="(item,index) in m9Data" :key="index" :src="item.img" alt />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footp">
      <div>Copyright © 2019共青团中央维护青少年权益部所有，未经许可不得转载</div>
      <!-- <div>北京新新向荣科技有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script scope>
const columns1 = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    width: 65
  },
  {
    title: "类别",
    dataIndex: "catalog",
    align: "center",
    width: 150,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案组别",
    dataIndex: "group",
    align: "center",
    width: 110,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案题目",
    dataIndex: "title",
    align: "center",
    width: 350,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案人/提案团队",
    dataIndex: "posted_by",
    align: "center",
    width: 170,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: " 学校",
    dataIndex: "school",
    align: "center",
    width: 220,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "省份",
    dataIndex: "province",
    align: "center",
    width: 80,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  }
];
const columns = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    width: 65
  },
  {
    title: "类别",
    dataIndex: "catalog",
    align: "center",
    width: 150,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案组别",
    dataIndex: "group",
    align: "center",
    width: 110,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案题目",
    dataIndex: "title",
    align: "center",
    width: 350,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "提案人/提案团队",
    dataIndex: "posted_by",
    align: "center",
    width: 170,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: " 学校",
    dataIndex: "school",
    align: "center",
    width: 220,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "推荐单位",
    dataIndex: "unit",
    align: "center",
    width: 200,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  },
  {
    title: "省份",
    dataIndex: "province",
    align: "center",
    width: 80,
    scopedSlots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customRender"
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    }
  }
];
export default {
  data() {
    return {
      searchText: "",
      searchInput: null,
      searchValue: "",
      buttonType1: "primary",
      buttonType2: "default",
      buttonType3: "default",
      buttonType4: "default",
      classShow1: true,
      classShow2: false,
      classShow3: false,
      classShow4: false,
      dataSource: [],
      teacherpData: [
        {
          img: require("../assets/images/t_1.png"),
          name: "陈永生",
          info: "江西科技学院团委副书记"
        },
        {
          img: require("../assets/images/t_2.png"),
          name: "庄海晨",
          info: "杭州学军中学团委书记、政治教师"
        },
        {
          img: require("../assets/images/t_3.png"),
          name: "董良瀚",
          info: "民革福建省委专职副主委"
        },
        {
          img: require("../assets/images/t_4.png"),
          name: "郭健",
          info: "山东财经大学财政税务学院税收系副主任"
        },
        {
          img: require("../assets/images/t_5.png"),
          name: "郭永森",
          info: "衡水第一中学团委副书记"
        },
         {
          img: require("../assets/images/t_6.png"),
          name: "金学琴",
          info: "高县职业技术学校教育处副主任兼团委书记"
        },
        {
          img: require("../assets/images/t_7.png"),
          name: "拉毛才让",
          info: "尖扎县民族中学团委书记"
        },
        {
          img: require("../assets/images/t_8.png"),
          name: "李昌盛",
          info: "朝阳工程技术学校团委干事"
        },
        {
          img: require("../assets/images/t_9.png"),
          name: "吕青",
          info: "徐州市云龙区人民检察院党组书记、检察长"
        },
        {
          img: require("../assets/images/t_10.png"),
          name: "蒲浩",
          info: "省政协提案委员会办公室干部"
        },
        {
          img: require("../assets/images/t_11.png"),
          name: "陶光均",
          info: "桂林医学院团委实践部部长"
        },
        {
          img: require("../assets/images/t_12.png"),
          name: "谢彬",
          info: "宜昌夷陵中学政治教师"
        },
        {
          img: require("../assets/images/t_13.png"),
          name: "谢滟檬",
          info: "顺义区杨镇第一中学高中政治教师"
        },
        {
          img: require("../assets/images/t_14.png"),
          name: "于健",
          info: "呼伦贝尔职业技术学院团委书记"
        },
        {
          img: require("../assets/images/t_15.png"),
          name: "袁力",
          info: "池州学院机电工程学院团总支书记"
        },
        {
          img: require("../assets/images/t_16.png"),
          name: "张慧源",
          info: "华东师范大学第三附属中学政治教师"
        },
        {
          img: require("../assets/images/t_17.png"),
          name: "周苹逢",
          info: "重庆警察学院主任科员"
        },
        // 以下无照片
        {
          img: 'http://ayx-cppcc.oss-cn-beijing.aliyuncs.com/images/0bd0dca0-6d75-11ea-9756-c73fb3ba900f.png',//meigengh
          name: "袁林方",
          info: "郑州12355青少年服务中心主任"
        },
        {
          img: 'http://ayx-cppcc.oss-cn-beijing.aliyuncs.com/images/3bf19230-6d75-11ea-9756-c73fb3ba900f.png',//mei
          name: "陈逸胜",
          info: "广州市黄埔广附实验学校历史教师"
        },
        {
          img: 'http://ayx-cppcc.oss-cn-beijing.aliyuncs.com/images/4de47340-6d75-11ea-9756-c73fb3ba900f.png',//mei
          name: "马金崇",
          info: "耿马傣族佤族自治县政协提案委员会副主任"
        },
      ],
      m9Data: [
        { img: require("../assets/images/unit_1.png") },
        { img: require("../assets/images/unit_2.png") },
        { img: require("../assets/images/unit_3.png") },
        { img: require("../assets/images/unit_4.png") },
        { img: require("../assets/images/unit_5.png") },
        { img: require("../assets/images/unit_6.png") },
        { img: require("../assets/images/unit_7.png") },
        { img: require("../assets/images/unit_8.png") },
        { img: require("../assets/images/unit_9.png") },
        { img: require("../assets/images/unit_10.png") },
        { img: require("../assets/images/unit_11.png") },
        { img: require("../assets/images/unit_12.png") },
        { img: require("../assets/images/unit_13.png") },
        { img: require("../assets/images/unit_14.png") },
        { img: require("../assets/images/unit_15.png") },
        { img: require("../assets/images/unit_16.png") },
        { img: require("../assets/images/unit_17.png") },
        { img: require("../assets/images/unit_18.png") },
        { img: require("../assets/images/unit_19.png") },
        { img: require("../assets/images/unit_20.png") },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
        total: 0,
        showSizeChanger: true,
        // showQuickJumper: true,
        showTotal: total => `共 ${total} 条数据`,
        pageSizeOptions: ["100", "300", "600","1000"],
        onShowSizeChange: (current, pageSize) =>
          this.showSizeChange(current, pageSize) //pageSize 变化的回调
        // onChange: (page, pageSize) //切换页码事件
      },
      loading: false,
      columns,
      columns1,
      scrollTocontentp: false,
      screenHeight: "",
      centralProposal: [],
      goodProposal: []
    };
  },
  methods: {
    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
      console.log(this.searchText, "this.searchText");
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    onSearch() {
      console.log(this.searchValue);
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    showSizeChange(current, pageSize) {
      this.pagination.pageSize = pageSize;
    },
    goHome() {
      this.$router.push("/home");
    },
    changeClass(value) {
      this.searchText =""
      if (value == 1) {
        this.classShow1 = true;
        this.classShow3 = false;
        this.classShow2 = false;
        this.classShow4 = false;
        // this.dataSource = this.goodProposal;
        this.pagination.total = this.goodProposal.length;
        this.buttonType1 = "primary";
        this.buttonType2 = "default";
        this.buttonType3 = "default";
        this.buttonType4 = "default";
      } else if (value == 2) {
        this.classShow1 = false;
        this.classShow2 = true;
        this.classShow3 = false;
        this.classShow4 = false;
        this.pagination.total = this.centralProposal.length;
        // this.dataSource = this.centralProposal;
        this.buttonType1 = "default";
        this.buttonType2 = "primary";
        this.buttonType3 = "default";
        this.buttonType4 = "default";
      } else if (value == 3) {
        this.classShow1 = false;
        this.classShow2 = false;
        this.classShow3 = true;
        this.classShow4 = false;
        this.buttonType1 = "default";
        this.buttonType2 = "default";
        this.buttonType3 = "primary";
        this.buttonType4 = "default";
      } else if (value == 4) {
         this.classShow1 = false;
        this.classShow2 = false;
        this.classShow3 = false;
        this.classShow4 = true;
        this.buttonType1 = "default";
        this.buttonType2 = "default";
        this.buttonType3 = "default";
        this.buttonType4 = "primary";
      }
    }
  },
  mounted() {
    this.centralProposal = require("../assets/json/centralProposal.json");
    this.goodProposal = require("../assets/json/goodProposal.json");
    console.log(this.centralProposal, "====centralProposal");
    let key = this.$route.query.key;
    this.changeClass(key);
    if (!key) {
      this.changeClass(1);
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.screenHeight = window.screenHeight;
      })();
    };
    // let headp = document.getElementsByClassName("headp")[0];
    // headp.style.height = this.screenHeight + "px";
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    screenHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenHeight值改变，就将其重新赋给data里的screenHeight
        this.screenHeight = val;
        this.timer = true;
        let that = this;
        // let headp = document.getElementsByClassName("headp")[0];
        // headp.style.height = that.screenHeight + "px";
        that.timer = false;
      }
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style>
.suspensionP {
  width: 154px;
  height: 250px;
  /* border: 1px solid red; */
  z-index: 43;
  position: fixed;
  right: 0;
  top: 250px;
}
.suspensionP img {
  width: 154px;
  /* height: 18px; */
}.suspensionP div {
  width: 134px;
  height: 40px;
  margin-left: 1px;
  line-height: 40px;
  /* text-align: center; */
  padding-left: 20px;
  font-size: 16px;
  /* margin: 2px; */
  color: #ffffff;
  /* border: 1px solid red; */
  background: #7eaaf3;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.greatUnit {
  /* width: 1200px; */
  height: 600px;
  margin: 0 auto;
}
.greatUnit img {
  /* width: 1200px; */
  height: 480px;
  margin-top: 80px;
}
.unit_box {
  /* width: 1130px; */
  /* height: 500px; */
  /* border: 1px solid yellowgreen; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: auto 0;
  justify-content: space-around;
}
.unit_box img {
  /* flex: 1; */
  /* width: 240px; */
  width: 20%;
  height: auto;
  /* border: 1px solid gainsboro; */
  margin-left: 20px;
  margin-top: 7px;
  margin-right: 20px;
}
.greatTeacher {
  width: 100%;
  height: 1700px;
}
.teacherp_box {
  width: 100%;
  height: 1650px;
  margin-top: 50px;
}
.teacherp_cbox {
  /* width: 1160px; */
  height: 100%;
  /* border: 1px solid red; */
  margin: 0 auto;
  display: flex;

  flex-wrap: wrap;
}
.teacherp_item {
  /* width: 230px; */
  height: 290px;
  /* margin-right: 30px;
  margin-left: 30px; */
  padding: 0 10px;
  margin:  0 20px;
  /* border: 2px solid red; */
  background: #fbfbfb;
}
.teacherp_avatar {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 30px;
  overflow: hidden;
}
.teacherp_avatar img {
  width: 140px;
  /* height: 140px; */
}
.teacherp_name {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  color: #1a76ef;
}
.teacherp_info {
  width: 180px;
  height: 100px;
  /* line-height: 40px; */
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: gray;
}
.footp {
  width: 100%;
  /* border: 1px solid yellowgreen; */
  background: #2d2d2d;
  color: #f8f8f8;
  padding: 30px 0;
  /* height: 100px; */
  /* padding-top: 20px; */
  /* position: absolute;
  bottom: 0;
  left: 0; */
}
.footp div {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}
.light {
  background: #f2faff;
}
.blue {
  background: #ffffff;
}
.ant-table-thead > tr > th {
  background: #d1edfe;
}
.winProposal {
  width: 92%;
  margin-left: 4%;
}
.boxp {
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* pos */
}
.contentp {
  width: 100%;
  min-height: 62%;
  /* height: auto; */
  /* height: 100%; */
  background: #ffffff;
  /* border: 1px solid #663030; */
}
.ant-table-thead {
  height: 40px;
}
.contentp_title {
  width: 100%;
  /* height: 80px; */
  height: 20px;
  /* margin-bottom: 30px; */
  line-height: 80px;
  text-align: center;
  color: #464646;
  font-size: 16px;
}
.contentp_nav {
  width: 80%;
  height: 40px;
  margin: 0 auto;
  /* border: 1px solid red; */
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
}
.nav_button {
  /* width: 900px; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid red; */
  /* margin-left: 23%; */
}
.nav_search {
  width: 175px;
  height: 40px;
  position: relative;
}
.nav_search input {
  width: 175px;
  height: 34px;
  outline: none;
  border-radius: 20px;
  margin-top: 3px;
  border: 1px solid #5ea3eb;
  padding-left: 15px;
}
.nav_search img {
  width: 20px;
  height: 20px;
  position: absolute;
  /* border: 1px solid #5EA3EB; */
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}
.a_button {
  width: 130px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  line-height: 35px;
  /* margin-left: 50px; */
}
.headp {
  /* width: 100%;
  height: 700px; */
  min-width: 100%;
  min-height: 600px;
  background-image: url("../assets/images/banner2.jpg");
  background-size: 100% auto;
  background-position: center center;
  position: relative;
}
/* .nav_bar {
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  top: 0;
  background: #215ed9;
  z-index: 35;
} */
.nav_bar_inheadp {
  width: 100%;
  height: 80px;
  cursor: pointer;
  /* border: 1px solid yellow; */
  display: flex;
  position: absolute;
  top: 5px;
  z-index: 35;
}
.headp_icon {
  width: 15%;
  height: 75px;
  margin-left: 10%;
  display: flex;
}
.headp_icon img {
  height: 75px;
  width: 75px;
}
.icon_title {
  width: 150px;
  height: 75px;
  font-weight: 400;
  line-height: 75px;
  color: #ffffff;
  font-size: 22px;
}
.headp_nav {
  width: 100px;
  height: 40px;
  margin-left: 68%;
  margin-top: 20px;
  line-height: 40px;
  /* border: 1px solid red; */
  display: flex;
}
.headp_nav div {
  text-align: center;
  flex: 1;
  color: #ffffff;
}
.headp_nav div:hover {
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}
</style>